import React, { useState, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/Seo";
import IndexProject from "../components/IndexProject";
import BackgroundImage from "gatsby-background-image";
import Contact from "../components/contact";
import LanguageSwitcher from "../components/langswitcher";
import { gsap } from "gsap";
import { Link, FormattedMessage } from "gatsby-plugin-intl";
import Logo from "../../content/assets/logo.svg";
import { MdTurnedInNot } from "react-icons/md";


const IPropIndex = ({ data, location, pageContext }) => {
  const contentRef = useRef(null);
  const linksRef = useRef(null);
  const paginationRef = useRef(null);
  const filterRef = useRef(null);
  const intervalRef = useRef(null);

  const projects = data.allMdx.edges;
  const { title, keywords, slogen } = data.site.siteMetadata;
  const { bgImage, bgImageTwo, bgImageThree } = data;
  const images = [bgImage, bgImageTwo, bgImageThree].map(imageData => imageData.childImageSharp.fluid);

  const [currentImage, setCurrentImage] = useState(0);
  const [triggerChange, setTriggerChange] = useState(false);
  const [starterAnimation, setStarterAnimation] = useState(true);

  let postCounter = 0;

  // Blur animation
  const blurIn = { backdropFilter: 'blur(30px)' };
  const blurOut = { backdropFilter: 'blur(0px)' };

  useEffect(() => {
    const intoAnimation = gsap.timeline();
    intoAnimation.from(contentRef.current, { delay: 2, opacity: 0, duration: 0.5, x: -50 })
      .from(linksRef.current, { delay: 0.5, opacity: 0, duration: 0.5, y: -50 })
      .from(paginationRef.current, { delay: 0.1, opacity: 0, duration: 0.5, y: -50 })
      .eventCallback('onComplete', () => setStarterAnimation(false));

    intervalRef.current = setInterval(() => {
      setTriggerChange(true);
    }, 10000);

    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (triggerChange) {
      const changeAnimation = gsap.to(filterRef.current, {
        duration: .3,
        ease: 'power2.inOut',
        onStart: () => {
          // Start blur animation
          gsap.to(filterRef.current, { duration: .15, ...blurIn });
        },
        onComplete: () => {
          // Change slide
          setCurrentImage((prevImage) => (prevImage + 1) % images.length); // This line changes the image
          gsap.to(filterRef.current, { duration: .15, ...blurOut });
        }
      });
      setTriggerChange(false);
    }

  }, [triggerChange]);

  useEffect(() => {
    clearInterval(intervalRef.current);

    const blurInAnimation = gsap.to(filterRef.current, {
      duration: .15,
      ease: 'power2.inOut',
      onStart: () => {
        gsap.to(filterRef.current, { duration: 0, ...blurIn });
      },
      onComplete: () => {
        setTriggerChange(false);

        intervalRef.current = setInterval(() => {
          setTriggerChange(true);
        }, 10000);

        gsap.to(filterRef.current, { duration: .15, ...blurOut });
      }
    });

    return () => {
      clearInterval(intervalRef.current);
      blurInAnimation.kill();
    };
  }, [currentImage]);


  return (
    <Layout title={title} home>
      <SEO />

      <BackgroundImage fluid={images[currentImage]} className={`page-head index-head ${starterAnimation ? "starter-animation" : "loaded-animation"}`}>
        <div className={`head-filter ${starterAnimation ? "animated" : "static"}`}></div>
        <div className={`secondary-head-filter`} ref={filterRef}></div>
        <div className='index-head-title'>
          <div ref={contentRef}>
            <img src={Logo} alt='logo' />
            <span className='name'>
              <FormattedMessage id='name' />
            </span>
            <span className='slogen'>
              <FormattedMessage id='slogen' />
            </span>
          </div>
        </div>

        <div className='index-links' ref={linksRef}>
          <Link to='/about'>
            <FormattedMessage id='links.about' />
          </Link>
          <Link to='/projects'>
            <FormattedMessage id='links.projects' />
          </Link>
          <LanguageSwitcher />
        </div>

        <div className={`glass-filter ${starterAnimation ? "starter-animation" : "loaded-animation"}`}></div>
      </BackgroundImage>

      <div className="pagination" ref={paginationRef}>
        {images.map((_, index) => (
          <span
            key={index}
            className={index === currentImage ? 'pagination-dot active' : 'pagination-dot'}
            onClick={() => setCurrentImage(index)}
          />
        ))}
      </div>

      <div className='index-feed'>
        {projects.map(({ node }) => {
          if (node.frontmatter.lang == pageContext.intl.language) {
            postCounter++;
            return (
              <IndexProject
                key={node.fields.slug}
                count={postCounter}
                node={node}
                postClass={`post`}
              />
            );
          }
        })}
      </div>

      <Contact />
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { main: { eq: true } } }
      sort: { fields: [frontmatter___main_prior], order: ASC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            description
            main_title
            main_desc
            location
            main_prior
            end
            finished
            lang
            thumbnailone {
              ...ImageFields
            }
            thumbnailtwo {
              ...ImageFields
            }
            thumbnailthree {
              ...ImageFields
            }
            logo {
              childImageSharp {
                fluid(maxWidth: 250) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    bgImage: file(relativePath: { eq: "nogradi_main.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bgImageTwo: file(relativePath: { eq: "horvat_main.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bgImageThree: file(relativePath: { eq: "komlossy_main.jpg" }) {
      id
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site: site {
      siteMetadata {
        title
        keywords
        slogen
        social {
          twitter
        }
        phone
        callable
        phone_name
        mail
      }
    }
  }

  fragment ImageFields on File {
    colors {
      ...GatsbyImageColors
    }
    childImageSharp {
      fluid(maxWidth: 1360) {
        ...GatsbyImageSharpFluid
      }
    }
  }

`;


const IPropIndexContainer = (props) => {
  const data = useStaticQuery(indexQuery);
  return <IPropIndex {...props} data={data} />;
};

export default IPropIndexContainer;

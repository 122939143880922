import React from "react"

import BackgroundImage from "gatsby-background-image"
import { Link, FormattedMessage } from "gatsby-plugin-intl"

export default (props) => <IndexProject {...props} />


const IndexProject = ({ count, node }) => {
  return (
    <article className={`index-project ${count % 2 === 0 && `index-project-inv`}`}>
      {node.frontmatter.thumbnailone ? (
        <div className="index-project-view col-8">
          <div className="image-slider">
            <BackgroundImage
              fluid={node.frontmatter.thumbnailone.childImageSharp.fluid}
              className="index-project-img"
              style={{ position: "absolute" }}
            ></BackgroundImage>
            <BackgroundImage
              fluid={node.frontmatter.thumbnailtwo.childImageSharp.fluid}
              className="index-project-img"
              style={{ position: "absolute" }}
            ></BackgroundImage>
            <BackgroundImage
              fluid={node.frontmatter.thumbnailthree.childImageSharp.fluid}
              className="index-project-img"
              style={{ position: "absolute" }}
            ></BackgroundImage>
          </div>
          <div className="index-project-img-thumb">
            <span>
              <span className="lighter">
                <FormattedMessage id="location" />
              </span>
              <br />
              {node.frontmatter.location}
            </span>
            <span>
              <span className="lighter">
                {node.frontmatter.finished ? (
                  <FormattedMessage id="finished" />
                ) : (
                  <FormattedMessage id="finish" />
                )}
              </span>
              <br />
              {node.frontmatter.end}
            </span>
          </div>
        </div>
      ) : null}

      <div className="index-project-content col-4">
        <div className="colors">
          <div className="colorRow">
            <div
              className="color"
              style={{ backgroundColor: node.frontmatter.thumbnailone.colors.vibrant }}
            >
              &#160;
            </div>
            <div
              className="color"
              style={{ backgroundColor: node.frontmatter.thumbnailone.colors.muted }}
            >
              &#160;
            </div>
            <div
              className="color"
              style={{ backgroundColor: node.frontmatter.thumbnailone.colors.darkVibrant }}
            >
              &#160;
            </div>
          </div>
          <div className="colorRow">
            <div
              className="color"
              style={{ backgroundColor: node.frontmatter.thumbnailtwo.colors.vibrant }}
            >
              &#160;
            </div>
            <div
              className="color"
              style={{ backgroundColor: node.frontmatter.thumbnailtwo.colors.muted }}
            >
              &#160;
            </div>
            <div
              className="color"
              style={{
                backgroundColor:
                  node.frontmatter.thumbnailtwo.colors.darkVibrant,
              }}
            >
              &#160;
            </div>
          </div>
          <div className="colorRow">
            <div
              className="color"
              style={{ backgroundColor: node.frontmatter.thumbnailthree.colors.vibrant }}
            >
              &#160;
            </div>
            <div
              className="color"
              style={{ backgroundColor: node.frontmatter.thumbnailthree.colors.muted }}
            >
              &#160;
            </div>
            <div
              className="color"
              style={{ backgroundColor: node.frontmatter.thumbnailthree.colors.darkVibrant }}
            >
              &#160;
            </div>
          </div>
        </div>
        <div>
          <h4 className="index-project-title">
            {node.frontmatter.main_title}
          </h4>
        </div>
        <div className="index-project-body">
          {node.frontmatter.main_desc}
          <br />
          <br />
          <Link to={node.fields.slug}>
            <button role="button" className="outline d-block">
              <FormattedMessage id="check" />
            </button>
          </Link>
        </div>
      </div>
    </article>
  )
}
